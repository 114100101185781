var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BFormGroup',{staticClass:"baseDatePicker",attrs:{"id":_vm.getUniqueId('formGroupId'),"label-for":_vm.datepickerId}},[_c('div',{staticClass:"d-flex w-100"},[_c('label',{staticClass:"label",attrs:{"for":_vm.inputId}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"label-suffix ml-2"},[_vm._t("suffix")],2)]),_c('BaseInput',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"baseInput",staticClass:"input baseInput",attrs:{"id":_vm.inputId,"value":_vm.formattedDate,"placeholder":_vm.placeholder,"validate-if-empty":false,"automation-id":_vm.getAutomationId('masked'),"validation-rules":{
			required: _vm.required,
			isValidDate: true,
			notBeforeFromDate: { fromDate: _vm.fromDate },
			afterMinDate: { minErrorDate: _vm.minErrorDate },
			beforeMaxDate: { maxErrorDate: _vm.maxErrorDate }
		},"validation-messages":{
			required: _vm.requiredErrorMsg,
			isValidDate: _vm.$t('error.selecteddateIsValidDate'),
			notBeforeFromDate: _vm.$t('error.selecteddateNotBeforeFromDate'),
			afterMinDate: _vm.afterMinDateErrorMsgComp,
			beforeMaxDate: _vm.maxDateErrorMsgComp
		}},on:{"blur":_vm.onInputText,"focus":function($event){return _vm.selectAll()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('BFormDatepicker',_vm._b({attrs:{"id":_vm.datepickerId,"placeholder":_vm.placeholder,"date-format-options":_vm.getDateFormatOptions(),"automation-id":_vm.getAutomationId('date-picker'),"min":_vm.minSelectableDate,"max":_vm.maxSelectableDate,"initial-date":_vm.initialDate,"no-flip":_vm.noFlip,"right":"","dropdown":_vm.noFlip,"size":_vm.size,"locale":_vm.calendarLocale,"button-only":"","selected-variant":"secondary","aria-controls":_vm.inputId,"nav-button-variant":"primary"},on:{"context":_vm.onContext},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"aria-label"},[_vm._v(_vm._s(_vm.label))]),_c('font-awesome-icon',{attrs:{"icon":['fal', 'calendar-alt']}})]},proxy:true}]),model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},'BFormDatepicker',_vm.$attrs,false))]},proxy:true}])},'BaseInput',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }