/**
 * Common date functions
 */

import { differenceInYears, isValid } from 'date-fns';

/**
 * Take the date format from the API ("yyyy-mm-dd") and convert to UI format ("dd/mm/yyyy")
 *
 * @param {String} inDate
 *
 * @example
 * const result = formatUI("1995-12-30")
 */
export function formatUi(inDate) {
	if (inDate) {
		return inDate.split('-').reverse().join('/');
	}
}

/**
 * Take the date format from the UI ("dd/mm/yyyy") and convert to API format ("yyyy-mm-dd")
 *
 * @param {String} inDate
 *
 * @example
 * const result = formatUI("30/12/1995")
 */
export function formatApi(inDate) {
	if (inDate) {
		return inDate.split('/').reverse().join('-');
	}
}
/**
 * Take the date format from the UI and convert to API format without slash or hyphen ("yyyymmdd")
 *
 * @param {String} inDate
 *
 * @example
 * const result = formatApiNSlash("19951230")
 */
export function formatApiNSlash(inDate) {
	var d = new Date(inDate);

	var month = '' + (d.getMonth() + 1);

	var day = '' + d.getDate();

	var year = d.getFullYear();

	if (month.length < 2) {
		month = '0' + month;
	}

	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('');
}

/**
 *
 * @param {String} date
 * @param {String} locale
 * @returns formated date (03 mai 2023 / 03 May 2023)
 */
export function formatDateForUi(date, locale) {
	date = new Date(date.substr(0, 4), date.substr(4, 2) - 1, date.substr(6, 2));
	const options = { year: 'numeric', month: 'short', day: '2-digit' };
	if (locale === 'en') {
		return date.toLocaleDateString('en-GB', options);
	} else {
		return date.toLocaleDateString('fr-CA', options);
	}
}

/**
 * Take in a string and convert it into a date, then check if it is valid.
 *
 * @param {String} date
 */
export function checkValidDate(date) {
	date = date.split(/\//).reverse().join('/');
	date = new Date(date);
	return isValid(date);
}

/**
 * Take a birth date in UI format ("dd/mm/yyyy") and check if it's under 19 years.
 *
 * @param {String} inDate
 * @return {Boolean} indicating if the age is under 19
 * @example
 *  const result = isUnderNineteen("30/12/1995")
 */
export function isUnderNineteen(inDate) {
	// Convert from dd/mm/yyyy to yyyy/mm/dd
	inDate = inDate.split(/\//).reverse().join('/');
	return differenceInYears(new Date(), new Date(inDate)) < 19 ? true : false;
}

/**
 * Take a birth date in UI format ("dd/mm/yyyy") and convert it to a date that works well with Javascript dates,
 * then return the age of the person.
 *
 * @param {String} dateOfBirth
 * @return {Number}
 */
export function getAge(dateOfBirth) {
	if (dateOfBirth) {
		dateOfBirth = dateOfBirth.split(/\//).reverse().join('/');
		return differenceInYears(new Date(), new Date(dateOfBirth));
	} else {
		return null;
	}
}

/**
 * @param {Date} date
 * @param {Number} amount
 * @param {String} dateType
 * @return {Date} returns the modified date with the selected datetype being affected
 * @example
 * const date = addDate(new Date(), -1, 'years')
 */
export function addDate(date, amount, dateType) {
	switch (dateType) {
		case 'days':
			return date.setDate(date.getDate() + amount) && date;
		case 'weeks':
			return date.setDate(date.getDate() + 7 * amount) && date;
		case 'months':
			return date.setMonth(date.getMonth() + amount) && date;
		case 'years':
			return date.setFullYear(date.getFullYear() + amount) && date;
	}
}
