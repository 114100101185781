<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BFormGroup :id="getUniqueId('formGroupId')" :label-for="datepickerId" class="baseDatePicker">
		<div class="d-flex w-100">
			<label class="label" :for="inputId">{{ label }}</label>
			<div class="label-suffix ml-2">
				<slot name="suffix"></slot>
			</div>
		</div>
		<BaseInput
			:id="inputId"
			ref="baseInput"
			v-mask="'##/##/####'"
			:value="formattedDate"
			class="input baseInput"
			:placeholder="placeholder"
			:validate-if-empty="false"
			v-bind="$attrs"
			:automation-id="getAutomationId('masked')"
			:validation-rules="{
				required: required,
				isValidDate: true,
				notBeforeFromDate: { fromDate },
				afterMinDate: { minErrorDate },
				beforeMaxDate: { maxErrorDate }
			}"
			:validation-messages="{
				required: requiredErrorMsg,
				isValidDate: $t('error.selecteddateIsValidDate'),
				notBeforeFromDate: $t('error.selecteddateNotBeforeFromDate'),
				afterMinDate: afterMinDateErrorMsgComp,
				beforeMaxDate: maxDateErrorMsgComp
			}"
			@blur="onInputText"
			@focus="selectAll()"
		>
			<template #append>
				<BFormDatepicker
					:id="datepickerId"
					v-model="selectedDate"
					v-bind="$attrs"
					:placeholder="placeholder"
					:date-format-options="getDateFormatOptions()"
					:automation-id="getAutomationId('date-picker')"
					:min="minSelectableDate"
					:max="maxSelectableDate"
					:initial-date="initialDate"
					:no-flip="noFlip"
					right
					:dropdown="noFlip"
					:size="size"
					:locale="calendarLocale"
					button-only
					selected-variant="secondary"
					:aria-controls="inputId"
					nav-button-variant="primary"
					@context="onContext"
				>
					<template #button-content>
						<!-- this span is required to pass accessibility -->
						<span class="aria-label">{{ label }}</span>
						<font-awesome-icon :icon="['fal', 'calendar-alt']" />
					</template>
				</BFormDatepicker>
			</template>
		</BaseInput>
	</BFormGroup>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import IdMixin from '@/mixins/id';
import { mask } from 'vue-the-mask';
import { checkValidDate } from '@/utils/date';
import BaseInput from '@/components/common/base/BaseInput.vue';
import { format } from 'date-fns';

extend('required', required);

/**
 * This extended validation rule is used to check if the date entered is a valid date.
 */
extend('isValidDate', {
	validate: (selectedDate) => {
		if (selectedDate) {
			const datePieces = selectedDate.split('/');
			const dateString = `${datePieces[2]}-${datePieces[1]}-${datePieces[0]}`;
			return !isNaN(new Date(dateString)) && dateString.length === 10;
		}
		return false;
	}
});

/**
 * This extended validation rule is used to check if the date entered in the component is before today and after min date.
 */
extend('notBeforeFromDate', {
	validate: (selectedDate, { fromDate }) => {
		if (fromDate.length < 1) {
			return true;
		} else {
			// If from date is invalid then don't throw an error. There should
			// already be one on the screen from the other date input.
			if (!checkValidDate(fromDate.toString())) {
				return true;
			}
			const datePieces = selectedDate.split('/');
			const fromPieces = fromDate.split('-');
			const selected = new Date(datePieces[2], datePieces[1] - 1, datePieces[0]);
			const from = new Date(fromPieces[0], fromPieces[1] - 1, fromPieces[2]);
			return selected >= from;
		}
	},
	params: ['fromDate']
});

/**
 * This extended validation rule is used to check if the date entered in the component is before today and after min date.
 */
extend('afterMinDate', {
	validate: (selectedDate, { minErrorDate }) => {
		if (minErrorDate.length < 1) {
			return true;
		} else {
			const datePieces = selectedDate.split('/');
			const minPieces = minErrorDate.split('-');
			const selected = new Date(datePieces[2], datePieces[1] - 1, datePieces[0]);
			const min = new Date(minPieces[0], minPieces[1] - 1, minPieces[2]);
			return selected >= min;
		}
	},
	params: ['minErrorDate']
});

extend('beforeMaxDate', {
	validate: (selectedDate, { maxErrorDate }) => {
		if (maxErrorDate.length < 1) {
			return true;
		} else {
			const datePieces = selectedDate.split('/');
			const maxPieces = maxErrorDate.split('-');
			const selected = new Date(datePieces[2], datePieces[1] - 1, datePieces[0]);
			const max = new Date(maxPieces[0], maxPieces[1] - 1, maxPieces[2]);
			return selected <= max;
		}
	},
	params: ['maxErrorDate']
});

/**
 * Component renders a date picker.
 */
// @vue/component
@Component({
	name: 'BaseDatePicker',
	components: {
		BFormGroup,
		BaseInput,
		BFormDatepicker
	},
	directives: { mask },
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		datepickerId: {
			type: String,
			default: 'datepickerId'
		},
		placeholder: {
			type: String,
			default: ''
		},
		/**
		 * initial value that will populate the input field.
		 */
		initialDate: {
			type: String,
			default: ''
		},
		/**
		 * The min date a member can select.
		 */
		minSelectableDate: {
			type: String,
			default: ''
		},
		/**
		 * The max date that a member can select.
		 */
		maxSelectableDate: {
			type: String,
			default: ''
		},
		/**
		 * The min date a member can select before getting an error.
		 */
		minErrorDate: {
			type: String,
			default: ''
		},
		/**
		 * The max date that a member can select before getting an error..
		 */
		maxErrorDate: {
			type: String,
			default: ''
		},
		/**
		 * fromDate is only used when this component is being used in a date range.
		 * it will validate that the 'to' date is not before this date.
		 */
		fromDate: {
			type: String,
			default: ''
		},
		/**
		 * This property controls the size of the date picker control. sm for small and lg for large.
		 */
		size: {
			type: String,
			default: 'sm'
		},
		required: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop is passed in from parent component to display an error message when the component is required
		 * but not given a value.
		 */
		requiredErrorMsg: {
			type: String,
			default: null
		},
		beforeMaxDateErrorMsg: {
			type: String,
			default: null
		},
		afterMinDateErrorMsg: {
			type: String,
			default: null
		},
		noFlip: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		value: function (newVal) {
			this.selectedDate = newVal;
		}
	}
})
export default class BaseDatePicker extends Vue {
	selectedDate = !this.value ? null : this.value;
	inputId = this.getUniqueId('input');

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get formattedDate() {
		// the replace functions fix the Date issue with UTC time causing the date to be incorrect by one day.
		if (this.selectedDate && checkValidDate(this.selectedDate) && this.selectedDate.length === 10) {
			const year = this.selectedDate.split('-')[0];
			const month = this.selectedDate.split('-')[1];
			const day = this.selectedDate.split('-')[2];
			return `${day}/${month}/${year}`;
		} else {
			return this.selectedDate;
		}
	}

	get calendarLocale() {
		if (this.locale === 'fr') {
			return 'fr';
		} else {
			return 'en-GB';
		}
	}

	get maxFormatted() {
		if (this.maxErrorDate.length > 0) {
			return format(
				new Date(
					this.maxErrorDate.split('-')[0],
					this.maxErrorDate.split('-')[1] - 1,
					this.maxErrorDate.split('-')[2]
				),
				'dd/MM/yyyy'
			);
		}
		return '';
	}

	get afterMinDateErrorMsgComp() {
		return this.afterMinDateErrorMsg
			? this.afterMinDateErrorMsg
			: this.$t('error.selecteddateBeforeTodayAndAfterMinDate', [this.minYears]);
	}

	get maxDateErrorMsgComp() {
		return this.beforeMaxDateErrorMsg
			? this.beforeMaxDateErrorMsg
			: this.$t('error.beforeMaxDate', [this.maxFormatted]);
	}

	/**
	 * This returns the format the calendar will display the date in the input
	 */
	getDateFormatOptions() {
		return { year: 'numeric', month: '2-digit', day: '2-digit' };
	}

	get minYears() {
		return new Date().getFullYear() - new Date(this.minErrorDate).getFullYear();
	}

	/**
	 * Emits the selected date when it is entered manullly instead of using the calendar.
	 */
	onInputText(e) {
		if (e.target.value && checkValidDate(e.target.value) && e.target.value.length === 10) {
			const datePieces = e.target.value.split('/');
			const dateString = `${datePieces[2]}-${datePieces[1]}-${datePieces[0]}`;
			this.selectedDate = dateString;
		} else {
			this.selectedDate = e.target.value;
		}
		this.$emit('input', this.selectedDate);
	}

	/**
	 * This method will update the selected date, and emit it to the parent when a value
	 * is updated on the calendar widget.
	 */
	onContext(newDate) {
		if (newDate.selectedDate && newDate.activeFormatted) {
			// Checking this flag so that the selectAll() function doesn't get called every time someone clicks on the calendar widget.
			this.datepickerClicked = true;
			this.$emit('input', format(newDate.selectedDate, 'yyyy-MM-dd'));
		}
	}

	/**
	 * This method selects all the text in an input
	 */
	selectAll() {
		// Only select everything if the input is what was clicked.
		if (!this.datepickerClicked) {
			this.$refs.baseInput.$refs.input.select();
		}
		this.datepickerClicked = false;
	}
}
</script>
<!-- *************************************************************************
	Style
	************************************************************************* -->

<style lang="scss" scoped>
.label {
	font-family: $josefin-font-family;
	font-size: 18px;
}
.label-suffix {
	font-size: 15px;
	color: $black;
}
::v-deep .form-group {
	margin-bottom: 0;
}
::v-deep .dropdown-menu {
	border-radius: 10px;
	box-shadow: 0px 0px 10px #e5e5e5;
	border: none;
	& .b-calendar-header {
		margin-bottom: 0;
		& output {
			border-radius: 5px;
			border-color: $gray-light;
			font-weight: 300;
			font-size: 15px;
			font-family: $lato-font-family;
			& bdi {
				letter-spacing: 1px;
				font-weight: 300;
			}
		}
	}
	& .b-calendar-nav {
		margin-bottom: 0;
	}
	& button {
		padding-bottom: 0 !important;
	}
}
.baseDatePicker {
	margin-bottom: 1.5em;
	::v-deep .b-calendar-grid-caption {
		font-family: $josefin-font-family;
		font-weight: $font-regular !important;
		font-size: $body-regular-lable-title-font-size;
		color: $white;
		background-color: $blue;
	}
	& ::v-deep .form-control {
		font-size: $body-small-font-size;
		color: $black;
	}
	& ::v-deep .input-group > .custom-select:not(:last-child),
	& ::v-deep .input-group > .form-control:not(:last-child) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		background-color: $gray-very-light;
		letter-spacing: 1px;
	}
	& ::v-deep .btn-secondary {
		border-color: $gray-dark;
		background-color: $white;
	}
	& ::v-deep .btn-secondary:hover {
		background-color: $blue-dark;
		color: $white;
	}
	& ::v-deep .btn-outline-light:hover {
		background-color: $calendar-day-hover-blue;
		color: $blue-dark !important;
	}
	& ::v-deep .btn-outline-primary:hover {
		background-color: $white;
	}
	& ::v-deep .btn-outline-primary:active {
		color: $blue;
	}
	& ::v-deep .btn-secondary:not(:disabled):not(.disabled).active {
		background-color: $blue-dark !important;
		outline: 3px solid $blue !important;
	}
	& ::v-deep .text-dark {
		color: $black !important;
	}
	& ::v-deep .text-muted,
	::v-deep .disabled {
		color: $gray !important;
		font-weight: 400 !important;
		opacity: 1 !important;
	}
	& ::v-deep .b-calendar-grid {
		border: none !important;
	}
	& ::v-deep .b-calendar-grid-body {
		border-bottom: 1px solid $gray-light !important;
		border-left: 1px solid $gray-light !important;
		border-right: 1px solid $gray-light !important;
		border-radius: 0 0 5px 5px;
		background-color: $white;
		& div {
			background-color: $white !important;
		}
		& :first-child {
			padding-top: 5px;
		}
		& :last-child {
			padding-bottom: 5px;
		}
	}
	& ::v-deep .b-calendar-grid-weekdays {
		border-bottom: none !important;
		background-color: $blue;
		font-family: $body-small-font-family;
		font-weight: $font-light;
		font-size: $body-calendar-grid-font-size;
	}
	& ::v-deep .b-calendar-grid-weekdays > small {
		font-size: 13px;
		font-weight: 300;
		font-family: 'Lato', sans-serif;
		margin-bottom: 3px;
	}
	& ::v-deep .b-calendar-grid-help {
		display: none;
	}
	& ::v-deep .btn {
		padding: 0.5em 1em;
		min-width: 32px;
		height: 2.6em;
		align-items: center;
		color: $blue;
		border-radius: 0px 5px 5px 0px;
	}
	& ::v-deep .text-truncate {
		color: $white;
	}
}
.aria-label {
	width: 0px;
	height: 0px;
	display: inline-block;
	overflow: hidden;
}
</style>

<i18n>
{
  "en": {
	"error": {
		"selecteddateIsValidDate": "Please enter a valid date.",
		"selecteddateBeforeTodayAndAfterMinDate": "Our search results are limited to {0} years of history. ",
		"selecteddateNotBeforeFromDate": "The end date you entered occurs before the start date. Please try again.",
		"beforeMaxDate": "The date must be on or before {0}"
    }
   },
  "fr": {
	"error": {
		"selecteddateIsValidDate": "Veuillez entrer une date valide.",
		"selecteddateBeforeTodayAndAfterMinDate":"Il n’y a que {0} ans d’historique dans les résultats de recherche.  ",
		"selecteddateNotBeforeFromDate": "La date de fin que vous avez entrée précède la date de début. Veuillez réessayer.",
		"beforeMaxDate": "The date must be on or before {0} (FR)"
    }
  }
}
</i18n>
